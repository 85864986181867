import { App, Component } from 'vue'
import PageSearch from '@/components/page-search'
import PageContent from '@/components/page-content'
import PageModal from '@/components/page-modal'

const components: {
  [propName: string]: Component
} = {
  PageSearch,
  PageContent,
  PageModal
}

export default function registerCpn(app: App) {
  for (const key in components) {
    app.component(key, components[key])
  }
}
