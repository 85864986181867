import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import cache from '@/utils/cache'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/main'
  // },
  // {
  //   path: '/main',
  //   redirect: '/main/service/store'
  // },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main/main.vue'),
    children: [
      {
        path: '/main/service/store',
        component: () => import('@/views/main/service/store/store.vue')
      },
      {
        path: '/main/service/store/add',
        component: () => import('@/views/main/service/store/cpn/add.vue')
      },
      {
        path: '/main/service/category',
        component: () => import('@/views/main/service/category/category.vue')
      },
      {
        path: '/main/store/list',
        component: () => import('@/views/main/store/list/list.vue')
      },
      {
        path: '/main/store/detail',
        component: () => import('@/views/main/store/list/detail.vue')
      },
      {
        path: '/main/order/list',
        component: () => import('@/views/main/order/list/list.vue')
      },
      {
        path: '/main/order/order',
        component: () => import('@/views/main/order/order/order.vue')
      },
      {
        path: '/main/order/order/detail',
        component: () => import('@/views/main/order/order/detail.vue')
      },
      {
        path: '/main/order/bill',
        component: () => import('@/views/main/order/bill/bill.vue')
      },
      {
        path: '/main/promoter/list',
        component: () => import('@/views/main/promoter/list/list.vue')
      },
      {
        path: '/main/promoter/apply',
        component: () => import('@/views/main/promoter/apply/apply.vue')
      },
      {
        path: '/main/system/role',
        component: () => import('@/views/main/system/role/role.vue')
      },
      {
        path: '/main/system/user',
        component: () => import('@/views/main/system/user/user.vue')
      },
      {
        path: '/main/system/menu',
        component: () => import('@/views/main/system/menu/menu.vue')
      },
      {
        path: '/main/system/config',
        component: () => import('@/views/main/system/config/config.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 导航守卫  如果没有token 就跳到登录页
router.beforeEach((to) => {
  const token = cache.getCache('token')
  if (to.path !== '/login' && !token) {
    return '/login'
  }
  // 处理有token  / 或者 /main 自动跳到第一个菜单
  if ((to.path === '/' || to.path === '/main') && token) {
    const menuList = cache.getCache('menuList')
    if (menuList[0].children.length > 0) {
      router.push(menuList[0].children[0].url)
    }
  }
})

export default router
