import zglRequest from '../request'

// 完善客户信息
export function perfectInfo(data: any) {
  return zglRequest.post({
    url: '/customers/update',
    data
  })
}
// 完善客户信息
export function orderDetail(id: number | string) {
  return zglRequest.get({
    url: '/order/detail',
    params: {
      id
    }
  })
}