import menuList from '@/constant/menuList'
export function mapMenuPowerIds(powerIds: string) {
  const powerIdsArr = powerIds.split(',')
  const newMenuList: any[] = []
  for (const item of menuList) {
    if (powerIdsArr.includes(item.id)) {
      if (item.children) {
        const itemTemp = { ...item, children: [] }
        for (const citem of item.children) {
          if (powerIdsArr.includes(citem.id)) {
            itemTemp.children.push(citem)
          }
        }
        newMenuList.push(item)
      } else {
        newMenuList.push(item)
      }
    }
  }
  return newMenuList
}
