const menuList: any[] = [
  {
    id: '1',
    name: '服务管理',
    url: '/main/service',
    children: [
      {
        id: '1-1',
        url: '/main/service/store',
        name: '商家服务'
      },
      {
        id: '1-2',
        url: '/main/service/category',
        name: '服务分类'
      }
    ]
  },
  {
    id: '2',
    name: '商家中心',
    url: '/main/store',
    children: [
      {
        id: '2-1',
        url: '/main/store/list',
        name: '商家列表'
      }
    ]
  },
  {
    id: '3',
    name: '客户订单',
    url: '/main/order',
    children: [
      {
        id: '3-1',
        url: '/main/order/list',
        name: '客户列表'
      },
      {
        id: '3-2',
        url: '/main/order/order',
        name: '订单管理'
      },
      {
        id: '3-3',
        url: '/main/order/bill',
        name: '账单流水'
      }
    ]
  },
  {
    id: '4',
    name: '推广者管理',
    url: '/main/promoter',
    children: [
      {
        id: '4-1',
        url: '/main/promoter/list',
        name: '推广者'
      },
      {
        id: '4-2',
        url: '/main/promoter/apply',
        name: '申请推广者'
      }
    ]
  },
  {
    id: '5',
    name: '系统配置',
    url: '/main/system',
    children: [
      {
        id: '5-1',
        url: '/main/system/role',
        name: '角色管理'
      },
      {
        id: '5-2',
        url: '/main/system/user',
        name: '用户管理'
      },
      {
        id: '5-3',
        url: '/main/system/config',
        name: '系统配置'
      }
    ]
  }
]

export default menuList
