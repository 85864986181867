import { defineStore } from 'pinia'
import {
  createPageData,
  deletePageData,
  editPageData,
  getPageListData
} from '@/service'
import useMainStore from '../modules/main'
const useSystemStore = defineStore('user', {
  state: () => ({
    userList: [],
    userCount: 0,
    roleList: [],
    roleCount: 0,
    menuList: [],
    menuCount: 0,
    applicationsList: [],
    applicationsCount: 0,
    spreadersList: [],
    spreadersCount: 0,
    customersList: [],
    customersCount: 0,
    businessList: [],
    businessCount: 0,
    serveList: [],
    serveCount: 0,
    orderList: [],
    orderCount: 0,
    billList: [],
    billCount: 0
  }),
  actions: {
    async fetchPageList(payload: any) {
      const { pageName, queryInfo } = payload
      const pageUrl = `/${pageName}/list`
      const res = await getPageListData(pageUrl, queryInfo)
      if (pageName === 'role') {
        ;(this as any)[`${pageName}List`] = res.data
      } else {
        ;(this as any)[`${pageName}List`] = res.data.list
        ;(this as any)[`${pageName}Count`] = res.data.total
      }
    },
    async fetchPageEdit(payload: any) {
      const { pageName, data } = payload
      const pageUrl = `${pageName}/update`
      await editPageData(pageUrl, data)
      let queryInfo = {}
      if (pageName === 'role') {
        queryInfo = {}
      } else {
        queryInfo = { pageIndex: 1, size: 10 }
      }
      this.fetchPageList({ pageName, queryInfo })
    },
    async fetchPageAdd(payload: any) {
      const mainStore = useMainStore()
      const { pageName, data } = payload
      const pageUrl = `/${pageName}/add`
      await createPageData(pageUrl, data)
      let queryInfo = {}
      if (pageName === 'role') {
        // 角色没有分页
        queryInfo = {}
      } else {
        queryInfo = { pageIndex: 1, pageSize: 10 }
      }
      // 当角色和商家新增的时候重新请求一下所有的数据
      switch (pageName) {
        case 'role':
          mainStore.getAllRoleList()
          break
        case 'business':
          mainStore.getAllStoreList()
          break
        default:
          break
      }
      this.fetchPageList({ pageName, queryInfo })
    },
    async fetchPageDel(payload: any) {
      const { pageName, id } = payload
      const pageUrl = `${pageName}/delete`
      await deletePageData(pageUrl, { id })
      let queryInfo = {}
      if (pageName === 'role') {
        queryInfo = {}
      } else {
        queryInfo = { pageIndex: 1, pageSize: 10 }
      }
      this.fetchPageList({ pageName, queryInfo })
    }
  },
  getters: {
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}List`]
      }
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}Count`]
      }
    }
  }
})

export default useSystemStore
