// 过滤对象中的空值
function filterNull(obj: any) {
  if (Object.prototype.toString.call(obj) !== '[object Object]') return
  const newObj: any = {}
  for (const key in obj) {
    const value = obj[key]
    if (value !== '' && value !== null && value !== undefined) {
      newObj[key] = value
    }
  }
  return newObj
}

export { filterNull }
