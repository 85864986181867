import zglRequest from '../request'

// 获取推广者提成
export function commissionNumData() {
  return zglRequest.get({
    url: '/commission/get'
  })
}
// 设置推广者提成
export function commissionNumEdit(num: string) {
  return zglRequest.post({
    url: '/commission/update',
    data: {
      num
    }
  })
}
