import zglRequest from '../request'

// 商家详情
export function storeDetail(id: any) {
  return zglRequest.get({
    url: '/business/detail',
    params: {
      id
    }
  })
}

// 商家的服务列表
export function serviceListById(params: any) {
  return zglRequest.get({
    url: '/business/serves',
    params
  })
}

// 重置密码
export function resetPsw(id: number) {
  return zglRequest.post({
    url: '/business/restPwd',
    data: {
      id
    }
  })
}
