import axios from 'axios'
import type { AxiosInstance } from 'axios'
import cache from '@/utils/cache'
import { ElMessage } from 'element-plus'
import router from '@/router'
const BASE_URL = process.env.VUE_APP_BASE_URL || ''

class ZGLRequest {
  instance: AxiosInstance
  constructor(baseURL: string, timeout = 10000) {
    this.instance = axios.create({
      baseURL,
      timeout
    })
    this.instance.interceptors.request.use((config: any) => {
      const token = cache.getCache('token')
      if (token) {
        config.headers['token'] = token
      }
      return config
    })
    this.instance.interceptors.response.use((response: any) => {
      let whiteUrls= ['/bill/downExcel']; // 不校验的白名单
      let apiUrl = response.config.url;
      const res = response.data
      if(!whiteUrls.some(item=> item == apiUrl)){
        if (res.code !== 10000) {
          ElMessage.error(res.msg)
          if (res.code === 10001) {
            // token失效就跳到登录页
            router.push('/login')
          }
        }
      }
      return response
    })
  }
  request(config: any) {
    return new Promise((resolve, reject) => {
      this.instance
        .request(config)
        .then((res) => {
          // console.log('request-then-res-------: ', res);
          resolve(res.data)
        })
        .catch((err) => {
          console.log('request-err: ', err);
          reject(err)
        })
    })
  }
  post(config: any) {
    return this.request({ ...config, method: 'post' })
  }
  get(config: any) {
    return this.request({ ...config, method: 'get' })
  }
  patch(config: any) {
    return this.request({ ...config, method: 'patch' })
  }
  delete(config: any) {
    return this.request({ ...config, method: 'delete' })
  }
  downFile(config: any) {
    return this.request({ ...config, method: 'get', responseType: 'blob'})
  }
}

export default new ZGLRequest(BASE_URL)
