import zglRequest from '../request'
import { filterNull } from '@/utils/format'
export function getPageListData(url: string, queryInfo: any = {}): any {
  return zglRequest.get({
    url,
    params: filterNull(queryInfo)
  })
}

export function createPageData(url: string, newData: any): any {
  return zglRequest.post({
    url,
    data: newData
  })
}

export function editPageData(url: string, editData: any): any {
  return zglRequest.post({
    url,
    data: editData
  })
}

export function deletePageData(url: string, params: any): any {
  return zglRequest.get({
    url,
    params
  })
}
