import zglRequest from '../request'

// 分类
export function serviceCategoryList() {
  return zglRequest.get({
    url: '/cates/list'
  })
}
// 批量审核
export function serviceBatchAudit(data: any) {
  return zglRequest.post({
    url: '/serve/check',
    data
  })
}

// 服务周期
export function serviceCycle() {
  return zglRequest.get({
    url: '/common/serveTime'
  })
}

// 新增服务
export function serviceAdd(data: any) {
  return zglRequest.post({
    url: '/serve/add',
    data
  })
}
// 编辑服务
export function serviceEdit(data: any) {
  return zglRequest.post({
    url: '/serve/update',
    data
  })
}

// 省
export function proviceList() {
  return zglRequest.get({
    url: '/common/provice'
  })
}
// 商家服务详情
export function serviceDetail(id: any) {
  return zglRequest.get({
    url: '/serve/detail',
    params: {
      id
    }
  })
}

// 服务分类新增
export function serviceCateAdd(data: any) {
  return zglRequest.post({
    url: '/cates/add',
    data
  })
}

// 服务分类编辑
export function serviceCateEdit(data: any) {
  return zglRequest.post({
    url: '/cates/update',
    data
  })
}
// 服务分类删除
export function serviceCateDel(id: number) {
  return zglRequest.get({
    url: '/cates/delete',
    params: {
      id
    }
  })
}
