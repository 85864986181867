import {
  getPageListData,
  serviceCategoryList,
  serviceCycle,
  proviceList
} from '@/service'
import { defineStore } from 'pinia'
import menuList from '@/constant/menuList'

const useMainStore = defineStore('main', {
  state: () => ({
    allRole: [], // 所有角色
    allMenu: menuList, // 菜单数据
    allCategory: [], // 所有分类
    allStore: [] as any[], // 所有商家
    allProvice: [] as any[], // 所有省
    serviceCycleList: [] as any[] // 服务周期
  }),
  actions: {
    initStoreData() {
      this.allMenu = menuList
      serviceCycle().then((res: any) => {
        this.serviceCycleList = res.data
      })
      proviceList().then((res: any) => {
        this.allProvice = res.data
      })
      this.getAllStoreList()
      this.getAllRoleList()
      this.getAllCategory()
    },
    async getAllCategory() {
      const res: any = await serviceCategoryList()
      this.allCategory = res.data
    },
    async getAllStoreList() {
      const res = await getPageListData('/business/list', {
        pageIndex: 1,
        pageSize: 1000
      })
      this.allStore = res.data.list
    },
    async getAllRoleList() {
      const res = await getPageListData('/role/list')
      this.allRole = res.data
    }
  }
})

export default useMainStore
